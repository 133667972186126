import Autorenew from '@mui/icons-material/Autorenew'
import Done from '@mui/icons-material/Done'
import HourglassEmpty from '@mui/icons-material/HourglassEmpty'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const TaskStatus: React.FC<{
  status: string | null | undefined
  onlyIcon?: boolean
  tSx?: object
  containerSx?: object
}> = ({status, onlyIcon, tSx = {}, containerSx}) => {
  const {t} = useTranslation()
  const renderStatusIcon = (status) => {
    switch (status) {
      case 'Done':
        return <Done sx={(theme) => ({color: theme.palette.success.main})} />
      case 'Ongoing':
        return <Autorenew />
      default:
        return <HourglassEmpty sx={(theme) => ({color: theme.palette.error.main})} />
    }
  }
  return (
    <>
      {onlyIcon ? (
        <Box sx={{width: '20px'}}>{renderStatusIcon(status)}</Box>
      ) : (
        <Box
          sx={{
            minWidth: '90px',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            gap: '3px',
            ...containerSx
          }}
        >
          {renderStatusIcon(status)}
          <Typography
            sx={{
              '&:first-letter': {
                textTransform: 'capitalize'
              },
              ...tSx
            }}
          >
            {status ? t(`taskStatuses.${status.toLowerCase()}`) : ''}
          </Typography>
        </Box>
      )}
    </>
  )
}
