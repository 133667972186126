import {AnalysisStatus} from '@hconnect/common/types'
import Autorenew from '@mui/icons-material/Autorenew'
import Check from '@mui/icons-material/Check'
import {TextField, MenuItem} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const AnalysisStatusSwitch = ({
  status,
  onChange,
  canReopen,
  canFinish
}: {
  status: AnalysisStatus
  onChange: (status: AnalysisStatus) => void
  canReopen?: boolean
  canFinish?: boolean
}) => {
  const {t} = useTranslation()
  return (
    <TextField
      select
      size="small"
      label="Status"
      variant="filled"
      value={status}
      fullWidth
      sx={{
        '& .MuiFormLabel-root': {
          color: 'rgba(255, 255, 255, 0.6)',
          '&.Mui-focused': {color: 'rgba(255, 255, 255, 0.6)'}
        }
      }}
      onChange={(e) => onChange(e.target.value as AnalysisStatus)}
      InputProps={{
        sx: {
          maxHeight: '48px',
          color: 'primary.contrastText',
          background: 'transparent',
          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
          border: '1px solid rgba(255, 255, 255, 0.4)',
          '&:hover': {
            background: 'transparent',
            border: '1px solid rgba(255, 255, 255)'
          },
          '&.Mui-focused': {
            background: 'transparent',
            border: '1px solid rgba(255, 255, 255, 0.4)'
          },
          '& .MuiSvgIcon-root': {
            color: 'white'
          },
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }
        }
      }}
    >
      <MenuItem
        value={AnalysisStatus.Finished}
        disabled={!canFinish}
        sx={{alignItems: 'center', display: 'flex', gap: 1}}
      >
        <Check color="success" sx={{fontSize: '16px'}} />
        {t(`taskStatuses.${AnalysisStatus.Finished.toLowerCase()}`)}
      </MenuItem>
      <MenuItem
        value={AnalysisStatus.Ongoing}
        disabled={!canReopen}
        sx={{alignItems: 'center', display: 'flex', gap: 1}}
      >
        <Autorenew sx={{fontSize: '16px'}} />
        {t(`taskStatuses.${AnalysisStatus.Ongoing.toLowerCase()}`)}
      </MenuItem>
    </TextField>
  )
}
